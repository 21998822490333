import {Component} from '@angular/core';

@Component({
    selector: 'fuse-demo-content',
    templateUrl: './demo-content.component.html'
})
export class FuseDemoContentComponent {
    /**
     * Constructor
     */
    constructor() {}
}
