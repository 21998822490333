import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {map} from 'rxjs/operators';
import {SERVER_API_URL} from 'app/app.constants';

@Injectable()
export class AuthServerProvider {
    constructor(
        private http: HttpClient,
        private $localStorage: LocalStorageService,
        private $sessionStorage: SessionStorageService
    ) {}

    getToken(): string {
        return this.$localStorage.retrieve('authenticationToken') || this.$sessionStorage.retrieve('authenticationToken');
    }

    clearAllTokens(): void {
        this.$localStorage.clear('authenticationToken');
        this.$sessionStorage.clear('authenticationToken');
        this.$sessionStorage.clear('isSuperUser');
        this.$localStorage.clear('isSuperUser');
    }
    login(credentials): Observable<any> {
        const data = {
            username: credentials.username,
            password: credentials.password,
            tenant: credentials.tenant,
            rememberMe: credentials.rememberMe,
            userId: credentials.userId
        };
        if (credentials.tenant === 'rm_admin') {
            data['apiKey'] = true;
        }
        return this.http
            .post(SERVER_API_URL + 'api' + (credentials.tenant === 'rm_admin' ? '/super-admin' : '') + '/authenticate', data, {
                observe: 'response'
            })
            .pipe(map(authenticateSuccess.bind(this)));

        function authenticateSuccess(resp): string {
            const bearerToken = resp.headers.get('Authorization');
            if (bearerToken && bearerToken.slice(0, 7) === 'Bearer ') {
                const jwt = bearerToken.slice(7, bearerToken.length);
                this.storeAuthenticationToken(jwt, credentials.rememberMe);
                return jwt;
            }
        }
    }

    create(credentials): Observable<any> {
        const data = {
            email: credentials.email,
            password: credentials.password,
            invitationCode: credentials.invitationCode,
            tenant: credentials.tenant
        };
        return this.http.post(SERVER_API_URL + 'api/invitation-create-account', data, {observe: 'response'});
    }

    loginWithToken(jwt, rememberMe): Promise<any> {
        if (jwt) {
            this.storeAuthenticationToken(jwt, rememberMe);
            return Promise.resolve(jwt);
        } else {
            return Promise.reject('auth-jwt-service Promise reject'); // Put appropriate error message here
        }
    }

    storeAuthenticationToken(jwt, rememberMe): void {
        if (rememberMe) {
            this.$localStorage.store('authenticationToken', jwt);
        } else {
            this.$sessionStorage.store('authenticationToken', jwt);
        }
    }

    logout(): Observable<any> {
        this.http.post(SERVER_API_URL + 'api/logout', null, {observe: 'response'}).subscribe();
        return new Observable((observer) => {
            this.clearAllTokens();
            observer.complete();
        });
    }

    getSSOAuthenticationUrl(tenant: string): Observable<string> {
        return this.http.get<string>(`${SERVER_API_URL}api/saml2/authenticate/${tenant}/processing-url`);
    }
}
