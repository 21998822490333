import {Component} from '@angular/core';

@Component({
    selector: 'fuse-demo-sidebar',
    templateUrl: './demo-sidebar.component.html'
})
export class FuseDemoSidebarComponent {
    /**
     * Constructor
     */
    constructor() {}
}
